import axios from 'axios'
import store from '@/store'
import router from '@/router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: process.env.VUE_APP_API_TIMEOUT
})

instance.interceptors.request.use((config) => {
  const { accessToken } = store.state.auth

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  config.headers['Cache-Control'] = 'no-cache'
  config.headers['Pragma'] = 'no-cache'

  return config
})

instance.interceptors.response.use(
  ({ data }) => data,
  async (error) => {
    if (error?.response?.status === 401) {
      if (error?.config?.url !== '/auth/refreshToken') {
        await store.dispatch('auth/refreshToken')
      } else {
        router.push({ name: 'Login' })
      }
    }

    return Promise.reject(error)
  }
)

export default instance
