import { parseHTTPError } from '@/helpers/errors'
import services from '@/services'

export default {
  namespaced: true,
  state: {
    currentCheckout: null,
    checkouts: {
      loading: false,
      error: null,
      data: []
    }
  },
  mutations: {
    setCheckouts(state, checkouts) {
      state.checkouts = {
        ...state.checkouts,
        ...checkouts
      }
    },
    setCurrentCheckout(state, currentCheckout) {
      state.currentCheckout = currentCheckout
    }
  },
  actions: {
    async fetchCheckouts({ commit, rootGetters }, { date } = {}) {
      const storeId = rootGetters['store/currentStoreId']

      if (storeId) {
        try {
          commit('setCheckouts', { loading: true })

          const checkouts = await services.checkouts.fetchCheckouts({
            storeId,
            date
          })

          commit('setCheckouts', {
            data: checkouts
          })

          const currentCheckout = checkouts.find(({ closedAt }) => !closedAt)

          // const checkouts = checkouts[0] ?? null

          if (currentCheckout) {
            const { id } = currentCheckout
            const { report, total } =
              await services.checkouts.fetchCheckoutReport({
                id
              })

            commit('setCurrentCheckout', {
              ...currentCheckout,
              report,
              total
            })
          } else {
            commit('setCurrentCheckout', null)
          }
        } catch (error) {
          commit('setCheckouts', {
            error: parseHTTPError(error)
          })
        } finally {
          commit('setCheckouts', { loading: false })
        }
      }
    }
  }
  // getters: {
  //   checkouts: (state) => state.checkouts.data?.find(({ closedAt }) => !closedAt) || null
  // }
}
