import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import Vuelidate from 'vuelidate'
import withToast from '@/mixins/withToast'
import VMoney from 'v-money'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/theme/default.scss'
import { parseHTTPError } from './helpers/errors'

Vue.config.productionTip = false

Vue.config.errorHandler = (error, vm, info) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(info, error.stack)
  }

  vm.$root.$toast.error(parseHTTPError(error))
}

Vue.use(BootstrapVue)
Vue.use(VueContentPlaceholders)
Vue.use(Vuelidate)
Vue.use(VMoney)

new Vue({
  router,
  store,
  mixins: [withToast],
  render: (h) => h(App)
}).$mount('#app')
