import services from '@/services'

export default {
  namespaced: true,
  state: {
    currentUser: null
  },
  mutations: {
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser
    }
  },
  actions: {
    async fetchCurrentUser({ commit, dispatch }) {
      const currentUser = await services.users.fetchCurrentUser()
      commit('setCurrentUser', currentUser)
      dispatch('setCurrentUserStore')
    },
    setCurrentUserStore({ commit, state }) {
      const mainStore = state.currentUser.userStores.find(({ main }) => main)

      if (mainStore) {
        commit('store/setCurrentStore', mainStore.store, { root: true })
        commit('store/setCurrentRole', mainStore.role, { root: true })
      }
    }
  },
  getters: {
    currentUserName: (state) => state.currentUser?.name ?? '',
    currentUserMainStore: (state) =>
      state.currentUser?.userStores?.find(({ main }) => main)
  }
}
