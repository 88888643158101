import auth from './modules/auth'
import checkouts from './modules/checkouts'
import customers from './modules/customers'
import operations from './modules/operations'
import payments from './modules/payments'
import periods from './modules/periods'
import products from './modules/products'
import rents from './modules/rents'
import reports from './modules/reports'
import stores from './modules/stores'
import toys from './modules/toys'
import users from './modules/users'
import zipcode from './modules/zipcode'

export default {
  auth,
  checkouts,
  customers,
  users,
  operations,
  payments,
  periods,
  products,
  rents,
  reports,
  stores,
  toys,
  zipcode
}
