import { format, isValid } from 'date-fns'
import ptBRLocale from 'date-fns/locale/pt-BR'
import { capitalize } from 'lodash/fp'

const formatDate = (date) =>
  format(new Date(date), 'yyyy-MM-dd', {
    locale: ptBRLocale
  })

const formatHours = (date) => {
  if (!isValid(new Date(date))) {
    return 'Data inválida' // Ou algum tratamento de erro
  }
  return format(new Date(date), 'HH:mm', {
    locale: ptBRLocale
  })
}

const formatStandardDateTime = (date) =>
  capitalize(
    format(new Date(date), 'dd/MM/yyyy HH:mm', {
      locale: ptBRLocale
    })
  )

const formatDateTime = (date) =>
  capitalize(
    format(new Date(date), "EEEE dd/MM/yyyy 'às' HH:mm:ss", {
      locale: ptBRLocale
    })
  )

export { formatDate, formatDateTime, formatHours, formatStandardDateTime }
