import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth from './modules/auth'
import checkout from './modules/checkout'
import store from './modules/store'
import user from './modules/user'
import periods from './modules/periods'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: process.env.VUE_APP_ID,
  storage: window.localStorage,
  reducer: ({ auth, store }) => ({
    auth,
    store
  })
})

export default new Vuex.Store({
  modules: {
    auth,
    checkout,
    store,
    user,
    periods
  },
  plugins: [vuexPersist.plugin]
})
