import { orderBy } from 'lodash/fp'
import services from '@/services'

export default {
  namespaced: true,
  state: {
    periods: {
      loading: false,
      error: null,
      data: []
    }
  },
  mutations: {
    setPeriods(state, periods) {
      state.periods = {
        ...state.periods,
        ...periods
      }
    }
  },
  actions: {
    async fetchPeriods({ commit }, { storeId }) {
      try {
        commit('setPeriods', { loading: true })
        const data = await services.periods.fetchPeriods({ storeId })
        commit('setPeriods', { data })
      } catch (error) {
        commit('setPeriods', { error: 'Erro ao carregar períodos' })
      } finally {
        commit('setPeriods', { loading: false })
      }
    }
  },
  getters: {
    periodsSortedByPrice(state) {
      return orderBy('price', 'desc', state.periods.data)
    },
    periodsSortedByTime(state) {
      return orderBy('time', 'asc', state.periods.data)
    }
  }
}
