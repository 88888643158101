import axios from 'axios'
import { parseZipCodeSearchAddress } from '@/helpers/misc'

const fetchAddressByZipCode = async (zipcode) => {
  const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
  if (data.erro) {
    throw Error('CEP inválido ou não encontrado')
  }

  return parseZipCodeSearchAddress(data)
}

export default {
  fetchAddressByZipCode
}
