const parseZipCodeSearchAddress = ({
  erro,
  logradouro,
  complemento,
  bairro,
  localidade,
  uf
}) => ({
  address: logradouro,
  complement: complemento,
  county: bairro,
  city: localidade,
  state: uf,
  error: erro
})

const parseMultiPartFormData = (data) => {
  const formData = new FormData()

  for (const key in data) {
    formData.append(key, data[key])
  }

  return formData
}

export { parseZipCodeSearchAddress, parseMultiPartFormData }
