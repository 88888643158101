import api from '@/services/api'

const RESOURCE_URI = '/auth'

const authenticate = ({ email, password }) =>
  api.post(RESOURCE_URI, { email, password })

const refreshToken = ({ accessToken, refreshToken }) =>
  api.post(`${RESOURCE_URI}/refreshToken`, { accessToken, refreshToken })

const changeStore = (storeId) => api.post(`${RESOURCE_URI}/store`, { storeId })

export default {
  authenticate,
  refreshToken,
  changeStore
}
