import api from '@/services/api'

const RESOURCE_URI = '/payment'

const createPayment = ({ type, amount, checkoutId, productId, rentId }) =>
  api.post(RESOURCE_URI, { type, amount, checkoutId, productId, rentId })

export default {
  createPayment
}
