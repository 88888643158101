import { omitBy, isNil } from 'lodash/fp'
import { HTTP_MULTIPART_CONFIG } from '@/constants'
import { parseMultiPartFormData } from '@/helpers/misc'
import api from '@/services/api'

const RESOURCE_URI = '/product'

const fetchAllProducts = () => api.get(`${RESOURCE_URI}/all`)
const fetchProducts = ({ storeId } = {}) => {
  const query = new URLSearchParams(omitBy(isNil, { storeId })).toString()

  return api.get([RESOURCE_URI, query].join('?'))
}
const fetchProductById = (id) => api.get(`${RESOURCE_URI}/${id}`)
const createProduct = (product) => {
  const formData = parseMultiPartFormData(product)
  return api.post(RESOURCE_URI, formData, HTTP_MULTIPART_CONFIG)
}
const updateProduct = (product) => {
  const formData = parseMultiPartFormData(product)
  return api.put(
    `${RESOURCE_URI}/${product.id}`,
    formData,
    HTTP_MULTIPART_CONFIG
  )
}

const removeProduct = (id) => api.delete(`${RESOURCE_URI}/${id}`)

export default {
  fetchProducts,
  fetchAllProducts,
  fetchProductById,
  createProduct,
  updateProduct,
  removeProduct
}
