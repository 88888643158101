import { omitBy, isNil } from 'lodash/fp'
import api from '@/services/api'

const RESOURCE_URI = '/checkout'

const fetchCheckouts = ({ storeId, date, fromDate, toDate } = {}) => {
  const query = new URLSearchParams(
    omitBy(isNil, { storeId, date, fromDate, toDate })
  ).toString()

  return api.get([RESOURCE_URI, query].join('?'))
}

const fetchCheckoutById = ({ id }) => api.get(`${RESOURCE_URI}/${id}`)

const openCheckout = (checkout) => api.post(RESOURCE_URI, checkout)

const closeCheckout = (checkout) =>
  api.post(`${RESOURCE_URI}/${checkout.id}/close`, checkout)

const fetchCheckoutReport = ({ id }) => api.get(`${RESOURCE_URI}/${id}/report`)

export default {
  fetchCheckouts,
  fetchCheckoutById,
  openCheckout,
  closeCheckout,
  fetchCheckoutReport
}
