import router from '@/router'
import services from '@/services'

export default {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    }
  },
  actions: {
    async authenticate({ dispatch }, { email, password }) {
      const { accessToken, refreshToken } = await services.auth.authenticate({
        email,
        password
      })
      dispatch('setTokens', { accessToken, refreshToken })
      await dispatch('user/fetchCurrentUser', null, { root: true })
    },
    setTokens({ commit }, { accessToken, refreshToken }) {
      commit('setAccessToken', accessToken)
      commit('setRefreshToken', refreshToken)
    },
    logout({ commit }) {
      commit('user/setCurrentUser', null, { root: true })
      commit('store/setCurrentStore', null, { root: true })
      commit('store/setCurrentRole', null, { root: true })
      commit('checkout/setCurrentCheckout', null, { root: true })
      commit('setAccessToken', null)
      router.push({ name: 'Login' })
    },
    async refreshToken({ dispatch, state }) {
      const { accessToken, refreshToken } = state
      const tokens = await services.auth.refreshToken({
        accessToken,
        refreshToken
      })
      dispatch('setTokens', tokens)
    }
  }
}
