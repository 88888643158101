import api from '@/services/api'

const RESOURCE_URI = '/customer'

const fetchCustomerByDocument = (document) =>
  api.get(`${RESOURCE_URI}/${document}`)
// api.post(RESOURCE_URI, { document })

export default {
  fetchCustomerByDocument
}
