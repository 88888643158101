import { formatDate } from '@/helpers/date'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta.public) {
    await store.dispatch('user/fetchCurrentUser', null, { root: true })
    store.dispatch('user/setCurrentUserStore', null, { root: true })
    await store.dispatch(
      'checkout/fetchCheckouts',
      { date: formatDate(new Date()) },
      { root: true }
    )

    // if (!store.state.store.currentStore && to.name !== 'SelectStore') {
    //   next({ name: 'SelectStore' })
    // }
  }
  next()
})

export default router
