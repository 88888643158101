export default {
  computed: {
    $toast() {
      return {
        success: (message) =>
          this.$bvToast.toast(message, {
            title: 'Operação Realizada',
            variant: 'success',
            autoHideDelay: 5000
          }),
        error: (message) =>
          this.$bvToast.toast(message, {
            title: 'Erro',
            variant: 'danger',
            autoHideDelay: 5000
          }),
        warning: (message) =>
          this.$bvToast.toast(message, {
            title: 'Atenção',
            variant: 'warning',
            autoHideDelay: 5000
          })
      }
    }
  }
}
