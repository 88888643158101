import api from '@/services/api'

const RESOURCE_URI = '/user'

const fetchCurrentUser = () => api.get(`${RESOURCE_URI}/me`)

const fetchUsers = () => api.get(RESOURCE_URI)
const fetchUserById = (id) => api.get(`${RESOURCE_URI}/${id}`)
const createUser = (user) => api.post(RESOURCE_URI, user)
const updateUser = (user) => api.put(`${RESOURCE_URI}/${user.id}`, user)
const removeUser = (id) => api.delete(`${RESOURCE_URI}/${id}`)

export default {
  fetchCurrentUser,
  fetchUsers,
  fetchUserById,
  createUser,
  updateUser,
  removeUser
}
