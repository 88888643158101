import { omitBy, isNil } from 'lodash/fp'
import api from '@/services/api'

const RESOURCE_URI = '/period'

const fetchPeriods = ({ storeId } = {}) => {
  const query = new URLSearchParams(omitBy(isNil, { storeId })).toString()
  return api.get([RESOURCE_URI, query].join('?'))
}
const fetchAllPeriods = () => api.get(`${RESOURCE_URI}/all`)
const fetchPeriodById = (id) => api.get(`${RESOURCE_URI}/${id}`)
const createPeriod = (period) => api.post(RESOURCE_URI, period)
const updatePeriod = (period) => api.put(`${RESOURCE_URI}/${period.id}`, period)
const removePeriod = (id) => api.delete(`${RESOURCE_URI}/${id}`)

export default {
  fetchPeriods,
  fetchAllPeriods,
  fetchPeriodById,
  createPeriod,
  updatePeriod,
  removePeriod
}
