import api from '@/services/api'

const RESOURCE_URI = '/operation'

const fetchOperations = ({ checkoutId, type, paymentId } = {}) => {
  const query = new URLSearchParams(
    JSON.parse(JSON.stringify({ checkoutId, type, paymentId }))
  ).toString()

  return api.get([`${RESOURCE_URI}`, query].join('?'))
}

const createOperation = (operation) => api.post(RESOURCE_URI, operation)

export default {
  fetchOperations,
  createOperation
}
