import { ROLES } from '@/constants'
// import services from '@/services'

export default {
  namespaced: true,
  state: {
    currentStore: null,
    currentRole: null
  },
  mutations: {
    setCurrentStore(state, store) {
      state.currentStore = store
    },
    setCurrentRole(state, role) {
      state.currentRole = role
    }
  },
  actions: {
    async setCurrentStore({ commit }, store) {
      // const { accessToken, refreshToken } = await services.stores.changeStore(
      //   store.id
      // )
      // commit('auth/setAccessToken', accessToken, { root: true })
      // commit('auth/setRefreshToken', refreshToken, { root: true })
      commit('setCurrentStore', store)
    }
  },
  getters: {
    isAdmin(state) {
      return state.currentRole === ROLES.ADMIN.value
    },
    isEmployer(state) {
      return state.currentRole === ROLES.EMPLOYER.value
    },
    isShopping(state) {
      return state.currentRole === ROLES.SHOPPING.value
    },
    currentStoreId(state) {
      return state.currentStore?.id ?? null
    }
  }
}
