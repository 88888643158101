export default [
  {
    path: '/',
    name: 'HomeIndex',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/Home/HomeIndex')
  },
  {
    path: '/cadastros',
    name: 'RegistersIndex',
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/RegistersIndex')
  },
  {
    path: '/caixas',
    name: 'CheckoutsIndex',
    component: () =>
      import(
        /* webpackChunkName: "checkout-index" */ '@/views/Checkouts/CheckoutsIndex'
      )
  },
  {
    path: '/lojas',
    name: 'StoresIndex',
    component: () =>
      import(
        /* webpackChunkName: "stores-index" */ '@/views/Stores/StoresIndex'
      )
  },
  {
    path: '/lojas/cadastro/:id?',
    name: 'StoreRegister',
    component: () =>
      import(
        /* webpackChunkName: "store-register" */ '@/views/Stores/StoreRegister'
      )
  },
  {
    path: '/brinquedos',
    name: 'ToysIndex',
    component: () =>
      import(/* webpackChunkName: "toys-index" */ '@/views/Toys/ToysIndex')
  },
  {
    path: '/brinquedos/cadastro/:id?',
    name: 'ToyRegister',
    component: () =>
      import(/* webpackChunkName: "toy-register" */ '@/views/Toys/ToyRegister')
  },
  {
    path: '/periodos',
    name: 'PeriodsIndex',
    component: () =>
      import(
        /* webpackChunkName: "periods-index" */ '@/views/Periods/PeriodsIndex'
      )
  },
  {
    path: '/periodos/cadastro/:id?',
    name: 'PeriodRegister',
    component: () =>
      import(
        /* webpackChunkName: "period-register" */ '@/views/Periods/PeriodRegister'
      )
  },
  {
    path: '/usuarios',
    name: 'UsersIndex',
    component: () =>
      import(/* webpackChunkName: "users-index" */ '@/views/Users/UsersIndex')
  },
  {
    path: '/usuarios/cadastro/:id?',
    name: 'UserRegister',
    component: () =>
      import(
        /* webpackChunkName: "user-register" */ '@/views/Users/UserRegister'
      )
  },
  {
    path: '/produtos',
    name: 'ProductsIndex',
    component: () =>
      import(
        /* webpackChunkName: "products-index" */ '@/views/Products/ProductsIndex'
      )
  },
  {
    path: '/produtos/cadastro/:id?',
    name: 'ProductRegister',
    component: () =>
      import(
        /* webpackChunkName: "product-register" */ '@/views/Products/ProductRegister'
      )
  },
  {
    path: '/relatorios',
    name: 'ReportsIndex',
    component: () =>
      import(
        /* webpackChunkName: "reports-index" */ '@/views/Reports/ReportsIndex'
      )
  },
  {
    path: '/relatorios/caixas',
    name: 'ReportCheckouts',
    component: () =>
      import(
        /* webpackChunkName: "reports-checkout" */ '@/views/Reports/Checkout'
      )
  },
  {
    path: '/relatorios/caixas/:id',
    name: 'ReportCheckoutDetails',
    component: () =>
      import(
        /* webpackChunkName: "reports-checkout-details" */ '@/views/Reports/CheckoutDetails'
      )
  },
  {
    path: '/relatorios/alugueis',
    name: 'ReportRents',
    component: () =>
      import(
        /* webpackChunkName: "reports-rents" */ '@/views/Reports/Rents/Rents'
      )
  },
  {
    path: '/relatorios/alugueis/brinquedo',
    name: 'ReportRentsPerToy',
    component: () =>
      import(
        /* webpackChunkName: "reports-rents-per-toy" */ '@/views/Reports/Rents/RentsPerToy'
      )
  },
  {
    path: '/relatorios/alugueis/usuario',
    name: 'ReportRentsPerUser',
    component: () =>
      import(
        /* webpackChunkName: "reports-rents-per-user" */ '@/views/Reports/Rents/RentsPerUser'
      )
  },
  {
    path: '/relatorios/vendas/produtos',
    name: 'ReportProductsPayment',
    component: () =>
      import(
        /* webpackChunkName: "products-payment" */ '@/views/Reports/Products/Products'
      )
  },
  {
    path: '/relatorios/vendas/pagamentos',
    name: 'ReportPaymentMethods',
    component: () =>
      import(
        /* webpackChunkName: "payment-methods" */ '@/views/Reports/PaymentMethods/PaymentMethods'
      )
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/Auth/Login'),
    meta: {
      public: true
    }
  },
  {
    path: '/recuperar-senha',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/Auth/ResetPassword'
      ),
    meta: {
      public: true
    }
  },
  {
    path: '/alterar-senha',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ '@/views/Auth/ChangePassword'
      )
  },
  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "not-found" */ '@/views/NotFound')
  }
]
