import { EXTRA_TIME_TYPES, HOME_ITEM_TYPES, RENT_STATUS } from '@/constants'
import { addMinutes, isAfter } from 'date-fns'
import { pipe, filter, reduce, isEmpty } from 'lodash/fp'
import toysService from '@/services/modules/toys'

const parseToysRents = (toys) =>
  toys.map(({ rents, ...toy }) => {
    const rent = rents[0] ?? null

    return {
      ...toy,
      type: HOME_ITEM_TYPES.TOY,
      rent
    }
  })

const calculateEndDate = (rent) => {
  if (!rent || !rent.period) return null

  const { createdAt } = rent
  const startDate = new Date(createdAt)

  const extraTime = calculateExtraTime(rent)
  const pausedTime = calculatePausedTime(rent)
  const totalTime = (rent.period?.time || 0) + extraTime + pausedTime
  return addMinutes(startDate, totalTime)
}

const calculatePeriod = (rent) => {
  const extraTime =
    rent.extraTime
      ?.filter(({ type }) => type !== 'pause')
      .reduce((acc, { minutes }) => (acc += minutes), 0) || 0

  const period = `${rent?.period?.time} min`

  return extraTime ? `${period} (+${extraTime} min)` : period
}

const calculateRentAmount = ({
  rent,
  elapsedTime,
  hasProducts,
  delayFeePerMinute = 1,
  toleranceMinutes = 2
}) => {
  // Verifica se o objeto rent está vazio
  if (isEmpty(rent)) {
    return {
      rentAmount: 0,
      productsAmount: 0
    }
  }

  const extraTime =
    rent.extraTime
      ?.filter(({ type }) => type !== 'pause')
      .reduce((acc, { minutes }) => (acc += minutes), 0) || 0

  // Ajusta elapsedTime subtraindo o extraTime
  const adjustedElapsedTime = elapsedTime - extraTime
  //
  const { price, time } = rent.period
  const isWithinToleranceTime = adjustedElapsedTime <= time + toleranceMinutes
  const rentAmount = isWithinToleranceTime
    ? price
    : price + (adjustedElapsedTime - time) * delayFeePerMinute
  const productsAmount = hasProducts ? calculateProductsAmount(rent) : 0

  return {
    rentAmount,
    productsAmount
  }
}

const calculateProductsAmount = (rent) =>
  rent.product.reduce((acc, product) => (acc += product.discountPrice), 0)

const calculatePausedTime = (rent) =>
  pipe(
    filter((it) => it.type === EXTRA_TIME_TYPES.PAUSE),
    reduce((acc, extraTime) => (acc += extraTime.minutes), 0)
  )(rent.extraTime)

const calculateExtraTime = (rent) =>
  pipe(
    filter((it) => it.type !== EXTRA_TIME_TYPES.PAUSE),
    reduce((acc, extraTime) => (acc += extraTime.minutes), 0)
  )(rent.extraTime)

// const isActive = (rent) => rent?.status === RENT_STATUS.ACTIVE.value
const isActive = (rent) =>
  rent?.status === RENT_STATUS.ACTIVE.value &&
  isEmpty(rent?.endedAt) &&
  isEmpty(rent?.canceledAt)

// const isPaused = (rent) => rent?.status === RENT_STATUS.PAUSED.value
const isPaused = (rent) =>
  rent?.status === RENT_STATUS.PAUSED.value &&
  isEmpty(rent?.endedAt) &&
  isEmpty(rent?.canceledAt)

const isDelayed = (rent) =>
  rent?.endedAt || rent?.canceledAt ? false : isAfter(new Date(), rent?.endDate)

const filterNulledPayments = (payments) =>
  payments.filter(({ amount }) => !!amount)

const filterActiveRentalsByPeriod = async (idPeriod) => {
  const fetchToys = await toysService.fetchToysRents()
  let matchingRents = []

  fetchToys.forEach((toy) => {
    if (toy.rents && toy.rents.length > 0) {
      toy.rents.forEach((rent) => {
        if (
          (rent.status === 'active' || rent.status === 'paused') &&
          rent.periodId === idPeriod
        ) {
          matchingRents.push({
            ...rent,
            toyName: toy.name
          })
        }
      })
    }
  })

  return matchingRents
}

const hasActiveRent = async (toyId) => {
  const toys = await toysService.fetchToysRents()

  const toy = toys.find((toy) => toy.id === toyId)
  return toy?.rents?.some(
    (rent) => rent.status != 'closed' && rent.status != 'canceled'
  )
}

export {
  parseToysRents,
  calculateEndDate,
  calculatePeriod,
  calculateRentAmount,
  calculatePausedTime,
  calculateExtraTime,
  filterNulledPayments,
  isActive,
  isPaused,
  isDelayed,
  filterActiveRentalsByPeriod,
  hasActiveRent
}
